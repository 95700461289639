@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500&subset=latin-ext');

body {
  position: relative;
  overflow-y: auto;
  font-family: 'Rubik', sans-serif;
  background-color: #fff;
}

h3 {
  font-size: 36px;
  font-weight: 300;
  color: #37474f;
}

p {
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  color: #263238;
}

section ul {
  list-style: none;
  padding: 0;

  li {
    font-size: 16px;
    font-weight: 300;
    color: #263238;
    padding-left: 1.5em;

    &::before {
      content: "\f00c";
      color: #0097a7;
      font-family: 'FontAwesome', sans-serif;
      display: inline-block;
      margin-left: -1.5em;
      width: 1.5em;
    }
  }
}

@media (max-width: 576px) {
  h2 {
    font-size: 1.5rem;
  }
}

.slideshow {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;

  @media (min-width: 1024px) {
    height: 70vh;
  }

  background-color: #383838;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  border-bottom: 25px solid #0097a7;

  .caption {
    flex-grow: 1;
  }
}

.portfolio {
  background-color: #cfd8dc;

  .card {
    background-color: #263238;
    border: 0;

    .card-image {
      position: relative;

      .overlay {
        position: absolute;
        right: 0;
        bottom: 100%;
        left: 0;
        width: 100%;
        height: 0;
        overflow: hidden;
        background-color: rgba(0, 172, 193, .7);
        transition: .5s ease;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;

        i {
          color: #fff;
          font-size: 36px;
        }
      }

      &:hover .overlay {
        bottom: 0;
        height: 100%;
      }
    }

    .card-body {
      h4 {
        font-size: 24px;
        font-weight: 400;
        color: #00bcd4;
      }

      p {
        font-size: 18px;
      }

      b {
        font-weight: 500;
      }
    }
  }
}

.contact {
  background-image: linear-gradient(25deg, rgba(0, 171, 194, .81), rgba(0, 194, 164, .80)), url("/d-projekty.cz/assets/img/contact.jpg");
  background-repeat: repeat-x, no-repeat;
  font-weight: 300;
  color: #fff;

  padding: 4rem 0;

  a {
    color: #fff;
  }

  .contact-basic {
    font-size: 20px;
  }

  .contact-email,
  .contact-phone {
    font-size: 24px;
  }

  @media (min-width: 768px) {
    .fa-envelope-open-o {
      font-size: 58px;
      vertical-align: -11px;
    }

    .fa-phone {
      font-size: 65px;
      vertical-align: -12px;
    }
  }
}

.navbar {
  @include transition(all .3s ease);
  background-color: transparent;

  &.navbar-scrolled {
    background-color: rgba(38, 50, 56, .75);
  }

  .navbar-brand {
    img {
      height: 70px;

      @media (max-width: 992px) {
        height: 55px;
      }
    }
  }

  @media (min-width: 992px) {
    .navbar-nav {
      .nav-link {
        padding: 40px 25px 13px !important;
        color: #fff !important;
        border-bottom: 3px solid transparent;

        &:hover,
        &.active {
          color: #0097a7 !important;
          background-color: #fff;
          border-color: #0097a7;
        }
      }
    }
  }
}

.btn {
  @include box-shadow(0 2px 6px 0 rgba(0, 0, 0, .25));
  font-size: 18px;
  border-radius: 0;

  &.btn-circle {
    background-color: #fff;
    color: #0097a7;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    padding: .25rem 15px;
    overflow: hidden;

    &.btn-expand {
      @include transition(all .3s cubic-bezier(.65, .05, .36, 1));

      .label {
        display: none;
      }

      &:hover {
        width: 100px;

        .label {
          display: inline-block;
        }
      }
    }

    .fa {
      font-size: 2em;
      vertical-align: -5px;
    }

    &:hover {
      background-color: #00acc1;
      color: #fff;
    }
  }

  &.btn-lg {
    padding: 15px 50px 12px;
  }
}
